import React, { useState } from "react";
//import Form from "react-bootstrap/Form";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { useAppContext } from "../libs/contextLib";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import "./Signup.css";
import { Auth } from "aws-amplify";

export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    confirmPassword: "",
    confirmationCode: "",
  });

  const history = useHistory();
  const [ newUser, setNewUser ] = useState(null);
  const { userHasAuthenticated } = useAppContext();
  const [ isLoading, setIsLoading ] = useState(false);

  function validateForm() {
    return (
        fields.email.length > 0 &&
        fields.password.length > 0 &&
        fields.password === fields.confirmPassword
    )
  };

  function validateConfirmationForm() {
    return fields.confirmationCode.length > 0;
  }
            
  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);

    try {
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,
      });
      setIsLoading(false);
      setNewUser(newUser);
    } catch (e) {
      setIsLoading(false);
    }
  }

  async function handleConfirmationSubmit(event) {
    event.preventDefault();
    setIsLoading(true);

    try {
      await Auth.confirmSignUp(fields.email, fields.confirmationCode);
      await Auth.signIn(fields.email, fields.password);

      userHasAuthenticated(true);
      history.push("/");
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }

  function renderConfirmationForm() {
    return (
        <form onSubmit={handleConfirmationSubmit}>
          <FormGroup controlId="confirmationCode" size="lg">
            <ControlLabel>Confirmation Code</ControlLabel>
            <FormControl type="tel" onChange={handleFieldChange} value={fields.confirmationCode} />
            <ControlLabel muted>Please check your email for the code.</ControlLabel>
          </FormGroup>
          <LoaderButton block size="lg" type="submit" variant="success" 
                isLoading={isLoading} disabled={!validateConfirmationForm()}>
            Verify
          </LoaderButton>
        </form>
    );
  }
 
  function renderForm() {
    return (
      <form onSubmit={handleSubmit}>
        <FormGroup controlId="email" size="lg">
          <ControlLabel>Email</ControlLabel>
          <FormControl autoFocus type="email" value={fields.email} onChange={handleFieldChange} />
        </FormGroup>
        <FormGroup controlId="password" size="lg">
          <ControlLabel>Password</ControlLabel>
          <FormControl type="password" onChange={handleFieldChange} value={fields.password}/>
        </FormGroup>
        <FormGroup controlId="confirmPassword" size="lg">
          <ControlLabel>Confirm Password</ControlLabel>
          <FormControl type="password" onChange={handleFieldChange} value={fields.confirmPassword}/>
        </FormGroup>
        <LoaderButton block 
            size="lg" 
            type="submit" 
            variant="success" 
            isLoading={isLoading} 
            disabled={!validateForm()}
        >
          Signup
        </LoaderButton>
      </form>
    );
  }

  return (
    <div className="Signup">
        {newUser === null ? renderForm() : renderConfirmationForm()}
    </div>
  );
}
























