
/*export default {
  MAX_ATTACHMENT_SIZE: 5000000,
  STRIPE_KEY: "pk_test_51HcvtmINsIHOP1KmFy2wWmZAtUxFJW6ECxvlvnBJvetunxSiJUahxKVr2hbsadkU0hnG6WwBAID3KhbAaCWM0rWG00QV86VXdn",
  s3: {
    REGION: "us-east-2",
    BUCKET: "sat-notes-app"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "https://5qehamqqk0.execute-api.us-east-2.amazonaws.com/prod"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_rGllU6s9e",
    APP_CLIENT_ID: "o5vuo44uk05r1197kr8un1sdj",
    IDENTITY_POOL_ID: "us-east-2:3f973848-8006-4303-8603-b7b78008a1c7",
  }
};*/

const dev = {
  STRIPE_KEY: "pk_test_51HcvtmINsIHOP1KmFy2wWmZAtUxFJW6ECxvlvnBJvetunxSiJUahxKVr2hbsadkU0hnG6WwBAID3KhbAaCWM0rWG00QV86VXdn",
  s3: {
    REGION: "us-east-2",
    BUCKET: "dev-notes-infra-s3-uploads4f6eb0fd-13xwprnslkp7o"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "api.supersimplenotes.com/dev"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_FW6YYabFt",
    APP_CLIENT_ID: "hdt10q5571jl4rne8hlp5au02",
    IDENTITY_POOL_ID: "us-east-2:5697eec2-992e-480c-ba35-0f6335cca5f4"
  }
};

const prod = {
  STRIPE_KEY: "pk_test_51HcvtmINsIHOP1KmFy2wWmZAtUxFJW6ECxvlvnBJvetunxSiJUahxKVr2hbsadkU0hnG6WwBAID3KhbAaCWM0rWG00QV86VXdn",
  s3: {
    REGION: "us-east-2",
    BUCKET: "prod-notes-infra-s3-uploads4f6eb0fd-1o35vbftdhsh"
  },
  apiGateway: {
    REGION: "us-east-2",
    URL: "api.supersimplenotes.com/prod"
  },
  cognito: {
    REGION: "us-east-2",
    USER_POOL_ID: "us-east-2_5rdMozGdI",
    APP_CLIENT_ID: "5c8uabv9l8ud6mpf3n6h7t6l9f",
    IDENTITY_POOL_ID: "us-east-2:d2c727c6-fd29-4995-9398-7052570b8f22"
  }
};

const config = {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  // Default to dev if not set
  ...(process.env.REACT_APP_STAGE === "prod" ? prod : dev),
};

export default config;
